<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center mb-4">
          <v-btn color="primary" small to="/liquidaciones">
            <v-icon left>mdi-arrow-left</v-icon>
            Volver
          </v-btn>
          <v-btn
            class="ml-1"
            color="primary"
            small
            @click.stop="getLiquidaciones"
          >
            <v-icon left>mdi-refresh</v-icon>
            Recargar
          </v-btn>
          <v-subheader>Desglose</v-subheader>
          <v-spacer></v-spacer>
          <v-select
            v-if="liquidacionesCabeceras.length > 0"
            dense
            hide-details
            filled
            clearable
            label="Liquidacion a desglosar"
            v-model="liquidacion"
            :items="liquidacionesCabeceras"
          ></v-select>
        </div>
        <v-divider></v-divider>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-simple-table class="text-no-wrap">
          <thead>
            <tr>
              <th v-if="$root.acceso('DEV')">ID</th>
              <th>Situación</th>
              <th>Agente</th>
              <th>Contrato</th>
              <th>R.</th>
              <th>Fecha Alta</th>
              <th>Fecha Baja</th>
              <th>CUPS</th>
              <th>Consumo Estimado (KWh)</th>
              <th>Producto</th>
              <th>Tarifa</th>
              <th colspan="2">Comisión</th>
              <th>Fecha liquidación</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="liq in liquidaciones">
              <tr :key="liq.idLiquidacion">
                <td v-if="$root.acceso('DEV')">{{ liq.idLiquidacion }}</td>
                <td class="remove-filter text-center" colspan="2">
                  {{ liq.canal }}
                </td>
                <td colspan="8">
                  <div
                    class="d-flex align-center justify-center"
                    style="gap: 10px"
                  >
                    <v-btn
                      v-if="
                        liq.idLiquidacion == ultimaLiquidacion.idLiquidacion &&
                          !ultimaLiquidacion.pagada
                      "
                      color="primary"
                      small
                      @click.stop="nuevaLinea = true"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Añadir línea
                    </v-btn>
                    <v-btn
                      v-if="
                        liq.idLiquidacion == ultimaLiquidacion.idLiquidacion &&
                          !liq.pagada
                      "
                      color="error"
                      small
                      @click.stop="deleteLiquidacion(liq.idLiquidacion)"
                    >
                      <v-icon small left>mdi-delete</v-icon>
                      Eliminar liquidacion
                    </v-btn>
                    <v-btn
                      @click.stop="getArchivo(liq.idLiquidacion)"
                      color="primary"
                      small
                    >
                      <v-icon left small>mdi-refresh</v-icon>
                      Generar factura
                    </v-btn>
                    <v-btn
                      v-if="liq.archivo"
                      @click.stop="getArchivo(liq.idLiquidacion, liq.archivo)"
                      color="primary"
                      small
                    >
                      <v-icon left small>mdi-file-document</v-icon>
                      Ver factura
                    </v-btn>
                    <v-btn
                      v-if="
                        liq.idLiquidacion == ultimaLiquidacion.idLiquidacion &&
                          !liq.pagada
                      "
                      :disabled="liq.acumulativo < 0"
                      color="warning darken-1"
                      small
                      @click.stop="pagarLiquidacion = true"
                    >
                      <v-icon small left>mdi-bank</v-icon>
                      Pagar
                    </v-btn>
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="success"
                          v-if="liq.pagada"
                          v-on="on"
                          v-bind="attrs"
                          >mdi-cash-check</v-icon
                        >
                        <v-icon color="error" v-else v-on="on" v-bind="attrs"
                          >mdi-cash-remove</v-icon
                        >
                      </template>
                      {{ liq.pagada ? "Pagada" : "No pagada" }}
                    </v-tooltip> -->
                    <v-btn
                      rounded
                      small
                      @click.stop="toggleOpen(liq.idLiquidacion)"
                      v-if="liq.lineas.length > 0"
                    >
                      <v-icon small left>mdi-unfold-more-horizontal</v-icon>
                      {{
                        liquidacion.includes(liq.idLiquidacion)
                          ? "Contraer"
                          : "Expandir"
                      }}
                    </v-btn>
                    <v-subheader class="font-italic">
                      <v-icon small left>mdi-alert-circle-outline</v-icon>
                      {{liq.lineas.length}} líneas</v-subheader
                    >
                  </div>
                </td>
                <td>
                  <v-chip
                    label
                    outlined
                    :color="liq.acumulativo < 0 ? 'error' : 'success'"
                  >
                    {{ parseFloat(liq.acumulativo).toFixed(2) }}
                    <v-icon small right>
                      mdi-currency-eur
                    </v-icon>
                  </v-chip>
                </td>
                <td>
                  <v-btn
                      color="secondary"
                      icon
                      @click.stop="comentario = liq "
                    >
                      <v-icon>mdi-message-processing-outline</v-icon>
                    </v-btn>
                </td>
                <td>{{ parseDate(liq.fechaLiquidacion) }}</td>
              </tr>
              <v-expand-transition
                appear
                v-for="linea in liquidacion.includes(liq.idLiquidacion)
                  ? liq.lineas
                  : []"
                :key="linea.idLinea"
              >
                <tr :class="{ 'error--text': linea.esDevolucion }">
                  <td v-if="$root.acceso('DEV')">
                    {{ linea.idLinea }}
                    <v-btn
                      @click.stop="deleteLinea(linea.idLinea)"
                      icon
                      outlined
                      color="error"
                      small
                      class="ml-1"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  <!-- <td style="position: relative;">
                      <div v-if="lineaIdx == 0" class="start-shape" />
                      <div v-else class="next-shape" />
                    </td> -->
                  <template v-if="linea.CodigoContrato">
                    <td><StatusChip :value="linea.SituacionContrato" /></td>
                    <td>{{ linea.NombreAgente }}</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            link
                            :to="
                              `/contrato/detalles?codigoContrato=${linea.CodigoContrato}&idContrato=${linea.IdContrato}`
                            "
                            color="secondary"
                            text
                            >{{ Math.abs(Number(linea.CodigoContrato)) }}</v-btn
                          >
                        </template>
                        <span>Detalles del contrato</span>
                      </v-tooltip>
                    </td>
                    <td>R.{{ linea.renovacion }}</td>
                    <td>{{ parseDate(linea.FechaAlta) }}</td>
                    <td>{{ parseDate(linea.FechaBaja) }}</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            link
                            :to="`/detalles_cups?cups=${linea.CodigoCUPS}`"
                            color="secondary"
                            text
                            >{{ linea.CodigoCUPS }}</v-btn
                          >
                        </template>
                        <span>Detalles del cups</span>
                      </v-tooltip>
                    </td>
                    <td>{{ linea.ConsumoEstimado }}</td>
                    <td>{{ linea.TarifaGrupo }}</td>
                    <td>{{ linea.Tarifa }}</td>
                  </template>
                  <td v-else colspan="10">
                    <div class="d-flex">
                      <v-btn
                        @click.stop="deleteLinea(linea.idLinea)"
                        v-if="
                          liq.idLiquidacion ==
                            ultimaLiquidacion.idLiquidacion && !liq.pagada
                        "
                        small
                        outlined
                        color="error"
                      >
                        <v-icon left small>mdi-delete</v-icon>
                        Eliminar
                      </v-btn>
                      <b class="flex-grow-1 text-center">{{
                        linea.descripcion
                      }}</b>
                    </div>
                  </td>
                  <td colspan="4">
                    <v-chip
                      label
                      outlined
                      class="v-chip--active"
                      :color="
                        linea.importeContrato < 0
                          ? 'error darken-1'
                          : 'success darken-1'
                      "
                    >
                      {{ parseFloat(linea.importeContrato).toFixed(2) }} €
                    </v-chip>
                    <v-chip label class="pa-1 ml-2" small
                      >{{ linea.porcentajeEspecial }} %</v-chip
                    >
                  </td>
                </tr>
              </v-expand-transition>
            </template>
          </tbody>
        </v-simple-table>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      </v-col>
      <v-col cols="12">
        <v-card-actions class="d-flex">
          <v-btn large @click.stop="exportToExcel">
            <v-icon left>mdi-microsoft-excel</v-icon>
            Exportar a Excel
          </v-btn>
          <v-btn large @click.stop="enviarCorreo = ultimaLiquidacion" :loading="sendingToUser">
            <v-icon left>mdi-email-fast</v-icon>
            Enviar excel acumulativo
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large @click.stop="liquidacionSinContratos">
            <v-icon left>mdi-plus</v-icon>
            Liquidacion sin contratos
          </v-btn>
          <v-btn
            large
            :to="
              `/liquidaciones/nueva?idUsuario=${idUsuario}&canal=${ultimaLiquidacion.canal}`
            "
          >
            <v-icon left>mdi-plus</v-icon>
            Nueva liquidacion
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-dialog v-model="nuevaLinea" max-width="600px">
      <NuevaLinea
        @reload="getLiquidaciones"
        v-model="nuevaLinea"
        :idLiquidacion="ultimaLiquidacion.idLiquidacion"
      />
    </v-dialog>

    <v-dialog v-model="pagarLiquidacion" max-width="600px">
      <PagarLiquidacion
        @reload="getLiquidaciones"
        v-model="pagarLiquidacion"
        :liq="ultimaLiquidacion"
        :idLiquidacion="ultimaLiquidacion.idLiquidacion"
      />
    </v-dialog>

    <v-dialog :value="comentario.idLiquidacion" @input="v => v === false ? comentario = {} : null" max-width="800px">
      <Comentario
        v-model="comentario"
        :key="comentario.idLiquidacion"
        @reload="getLiquidaciones"
        @close="comentario = {}"
      />
    </v-dialog>

    <v-dialog :value="enviarCorreo.idLiquidacion" @input="v => v === false ? enviarCorreo = {} : null" max-width="800px" scrollable>
      <EnviarExcelCorreo
        v-model="enviarCorreo"
        :key="enviarCorreo.idLiquidacion"
        @reload="getLiquidaciones"
        @close="enviarCorreo = {}"
      />
    </v-dialog>
        
    <ConfirmDialog ref="confirm" />

  </v-container>
</template>

<script>
import { parseDate, downloadFile } from "@/utils/index.js";

export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    NuevaLinea: () => import("../components/NuevaLinea.vue"),
    PagarLiquidacion: () => import("../components/PagarLiquidacion.vue"),
    Comentario: () => import("../components/Comentario.vue"),
    EnviarExcelCorreo: () => import("../components/EnviarExcelCorreo.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  props: {
    idUsuario: Number | String,
  },
  data() {
    return {
      liquidaciones: {},
      liquidacion: [],

      nuevaLinea: false,
      pagarLiquidacion: false,

      comentario: {},
      enviarCorreo: {},

      loading: false,
      sendingToUser: false,
    };
  },
  computed: {
    liquidacionesCabeceras() {
      const cabeceras = [
        // { value: null, text: "Todas" },
        ...Object.entries(this.liquidaciones).map(([key, liq]) => {
          return {
            value: key,
            text: parseDate(liq.fechaLiquidacion),
          };
        }),
      ];
      if (this.liquidacion == null && cabeceras.length > 0) {
        this.liquidacion = String(cabeceras.reverse()[0].idLiquidacion);
      }
      return cabeceras;
    },
    ultimaLiquidacion() {
      const liquidacionesOrdenadas = Object.values(this.liquidaciones).sort(
        (a, b) =>
          parseDate(b.fechaLiquidacion, true) -
            parseDate(a.fechaLiquidacion, true) ||
          b.idLiquidacion - a.idLiquidacion
      );
      if (liquidacionesOrdenadas.length > 0) {
        return {
          ...liquidacionesOrdenadas[0],
          lineas: null
        };
      } else return {};
    },
    canal() {
      return this.ultimaLiquidacion.canal || '';
    },
  },
  methods: {
    parseDate,
    async getLiquidaciones() {
      if (!this.idUsuario) return;
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/liquidaciones.php`,
        params: {
          idUsuario: this.idUsuario,
        },
      });
      // this.liquidaciones = data;
      this.$set(this.$data, "liquidaciones", data);
      this.loading = false;
    },
    async getArchivo(idLiquidacion, archivo) {
      this.loading = true;
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/get_factura.php`,
        params: {
          token: this.$store.getters.getJwtEmpresa,
          archivo,
          idLiquidacion,
        },
        responseType: "blob",
      })
        .then((res) => {
          downloadFile(
            new File([res.data], archivo, { type: "application/pdf" }),
            true
          );
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "Ha ocurrido un error");
        })
        .finally(() => {
          this.loading = false;
          this.getLiquidaciones();
        });
    },
    async deleteLiquidacion(idLiquidacion) {
      const r = await this.$refs.confirm.open(`¿ Estás seguro de que quieres eliminar la liquidación ?`, `Esta acción no se puede deshacer`, { color: 'error', confirmColor: 'error', width: 600 })
      if (!r) return;

      this.loading = true;
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/liquidaciones.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idLiquidacion: idLiquidacion,
        },
      });
      this.loading = false;
      this.getLiquidaciones();
    },
    async deleteLinea(idLinea) {
      const r = await this.$refs.confirm.open(`¿ Estás seguro de que quieres eliminar la línea ?`, `Esta acción no se puede deshacer`, { color: 'error', confirmColor: 'error', width: 600 })
      if (!r) return;

      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/nuevaLinea.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idLinea,
        },
      });
      this.getLiquidaciones();
    },
    toggleOpen(value) {
      var index = this.liquidacion.indexOf(value);

      if (index === -1) {
        this.liquidacion.push(value);
      } else {
        this.liquidacion.splice(index, 1);
      }
    },
    async liquidacionSinContratos() {
      this.loading = true;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario, IdAgente } = str.iss;

      axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/nuevaLiquidacion.php`,
        data: {
          canal: [this.ultimaLiquidacion.canal],
          token: this.$store.getters.getJwtEmpresa,
          selected: ["_"],
          IdAgente,
        },
      })
        .then((res) => {
          this.colaborador = null;
          this.$root.$emit("snack", "Liquidación generada correctamente");
          this.getLiquidaciones();
        })
        .finally(() => (this.loading = false));
    },
    async exportToExcel() {
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/acumulativoAnual.php`,
        responseType: "blob",
        params: {
          idUsuario: this.idUsuario,
          token: this.$store.getters.getJwtEmpresa,
          // idFactura: soloEsta ? this.datos.idFactura : null,
        },
      });
      const MESES = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", ];
      const excel = new File(
        [data],
        `Acumulativo_comisiones_${this.canal}_${this.ultimaLiquidacion.fechaLiquidacion}.xlsx`,
        {
          type: "application/excel",
        }
      );
      downloadFile(excel, false);
    },
  },
  mounted() {
    this.getLiquidaciones();
  },
};
</script>

<style scoped lang="scss">
.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  & > td,
  & > th,
  & > td,
  & > th,
  & > td,
  & > th {
    padding: 0, 8px;
  }
}

.start-shape::before {
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-30%, -50%);
  height: 3px;
  width: 70%;
  background: var(--v-primary-base);
}
.start-shape::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  height: calc(73% + 1px);
  width: 3px;
  background: var(--v-primary-base);
}

.next-shape::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 1px);
  width: 3px;
  background: var(--v-primary-base);
}
</style>
